.code-input {
  .wrap {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .input {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
    color: transparent !important;
    &:focus {
      outline: none;
    }
  }
  .code-box {
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: relative;
    margin-right: 10px;
    border-bottom: 1px solid #ffffff;
  }
  .code-box:last-child {
    margin-right: 0;
  }
  .shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 3px #ffcc10;
  }
}
