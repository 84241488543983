.image-uploader-container {
  .dx-fileuploader-wrapper {
    padding: 0;

    .dx-fileuploader-upload-button {
      display: none;
    }

    .dx-fileuploader-files-container {
      padding-top: 0;
    }
  }

  .preview {
    max-width: 100%;
    max-height: 400px;
  }
}
