@import '/_modals.scss';
@import '/_code_input.scss';
@import '/_image_uploader_container.scss';

.dataTables_filter {
  .form-control {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.dataTables_filter label input {
  border: 1px solid #dddddd;
  width: 200px;
  height: 34px;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 14px;
  padding: 10px;
  font-size: 14px;
}

.dataTables_info {
  padding-top: 10px;
  padding-left: 25px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 26px;
  float: left;

  &.dark-table {
    color: white;
  }
}

.dataTables_paginate {
  float: right;
  padding-right: 25px;
}

.dataTables_paginate {
  a {
    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    font-size: 14px;
    line-height: 20px;
    transition: 0.5s ease all 0s;
    -webkit-transition: 0.5s ease all 0s;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #c8c8c8;
    display: inline-block;
    vertical-align: middle;
    padding: 6px 8px;
    min-width: 36px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right: 1px solid #ccc;
    }
  }
  span a {
    border-right: 0 !important;
    border-radius: 0 !important;
  }
  a {
    &:hover {
      background: #ffc018;
      color: #222222;
      cursor: pointer;
      text-decoration: none;
      outline: none;
    }
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }
}

.pagination a,
.pagination li a,
.pagination li a.page-link,
.pagination li span,
.pagination li.disabled .page-link {
  border: none !important;
  border-radius: unset !important;
  background: none;
}

.pagination li a.page-link:hover {
  background: #ffc018;
  color: #fff;
}

.pagination li:first-child a.page-link,
.pagination li:last-child a.page-link {
  width: auto !important;
  padding: 5px;
}

.pagination li.disabled a.page-link {
  color: #575f7f;
}

.pagination li a.page-link {
  color: #8898aa;
}

nav ul.pagination {
  padding: 0;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-right: 15px;
  float: right;
}

.pagingHolder * {
  font-size: 13px !important;
}

.users-per-level-section,
.pending-withdraw-requests {
  border-radius: 5px;
  padding-left: 0;
  padding-right: 0;
  height: 345px;
  overflow-y: scroll;
}

.users-per-level-section {
  .react-datepicker-popper {
    left: -44px important;
  }
}

.input-group-append {
  padding: 13px 13px 13px 16px;
  margin-left: -1px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 3;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
}

.padding-none {
  padding: 0;
}

.padding-right-none {
  padding-right: 0;
}

.no-scrollbar {
  overflow: hidden !important;
}

.display-none {
  display: none;
}

.error {
  color: indianred;
  font-size: 13px;
}

.search_holder {
  float: right !important;
  margin: 5px 30px 0 0;
  width: auto;
  padding: 0;
  input,
  select {
    height: 35px;
    background-color: #f7f8fd;
  }
}

.element-in-new-line {
  padding-right: 0px;
  position: relative;
  right: -15px;
  top: 5px;
}

.feature-checkbox {
  width: 15px;
  height: 20px;
  transform: scale(1.3);
}

.propper-case {
  text-transform: capitalize;
}

.bottom-border {
  border-bottom: 1px solid #eee;
}

.user_content {
  border-top-left-radius: 0 !important;
}

.nav.nav-tabs {
  border-bottom: 0;
  cursor: pointer;
  .nav-item .nav-link {
    color: #fff;
    padding: 8px 10px !important;
  }
  .nav-item .nav-link.active {
    background-color: #fff;
    border-bottom: 1px solid #fff;
    color: #525f7f;
  }
}

.navbar-nav .nav-item.active {
  background-color: #d8edff;
}

.card-body .nav-link {
  padding: 0;
}

.error-code {
  font-weight: bold;
  font-size: 68px;
}

.error-message {
  font-size: 24px;
  margin-top: 30px;
}

@media only screen and (min-width: 1455px) {
  .card-title br {
    display: none;
  }
}

.table-responsive {
  overflow-x: inherit !important;
}

.flex-end-align {
  justify-content: flex-end;
}

.margin-top-20 {
  margin-top: 20px;
}

.no-padding-no-margin {
  padding: 0;
  margin: auto;
}

.left-ta {
  text-align: left;
}

.no-opacity {
  opacity: 0.3;
}
.display-flex-center-content {
  display: flex;
  justify-content: center;
}
.auto-margin {
  margin: auto;
}
.center-align {
  text-align: center;
}

.addBurnedButton {
  background-color: red;
}

.table-header-title {
  color: #f8f9fe;
  font-size: 13px;
}

.custom-row-select {
  background-color: transparent;
  max-width: fit-content;
  outline: unset;
  box-shadow: none;

  &:focus {
    background-color: transparent;
    border-color: #fff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.launchpad-project {
  .preview {
    width: 170px;
    height: 170px;
  }
}

.dashboard-page {
  .chart-visibility-options-row {
    height: 25px;
  }

  .dashboard-chart-date-filter-error {
    position: absolute;
    color: red;
    right: 190px;
    top: 40px;
  }

  .dashboard-users-per-level-date-filter-error {
    position: absolute;
    color: red;
    right: 25px;
    top: 45px;
  }

  .dashboard-users-per-level-date-filter-error {
    position: absolute;
    color: red;
    right: 25px;
    top: 45px;
  }
  .header {
    .card-stats {
      margin-bottom: 0 !important;
      height: 100%;

      .title {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}

.ordered-td {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.kyb-tab {
  .kyb-status {
    color: #495057; //$gray-700;
    padding-top: calc(0.625rem + 1px);
    padding-bottom: calc(0.625rem + 1px);

    .badge {
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }
  }

  .download-file {
    margin-bottom: 1.4rem;

    // &:last- {
    //   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    // }
  }

  .shufti-pro {
    color: #495057; //$gray-700;
    .card-header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      & h5 {
        font-weight: 400;
        color: #495057; //$gray-700;
      }
    }

    .card-info {
      .field {
        flex-basis: 50%;

        .title {
          flex-basis: 50%;
          padding-right: 10px;
        }
        .value {
          word-break: break-all;
          flex-basis: 50%;
        }
      }

      .splitter {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        flex-basis: 100%;
      }

      &--full-width {
        flex-basis: 100%;
      }
    }
  }
}
