.twoFALoginScreen {
  max-width: 380px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  .container {
    width: fit-content;
  }
  .navigate-buttons {
    text-align: center;
    button {
      border: 0 !important;
      width: 100%;
      background-color: #ffcc10;
      font-weight: bold;
      font-size: large;
      border-radius: 7px;
    }
  }
  .content {
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
  }
  .title {
    justify-content: space-around;
    display: flex;
    .image {
      margin: auto 0;
      img {
        width: 40px;
        margin-right: 15px !important;
        height: auto;
      }
    }
    h4 {
      color: white;
      margin: auto 0;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  h4,
  .text,
  .code-input {
    padding: 20px 0;
  }
  .navigate-buttons {
    padding: 20px 5px;
  }
  .modal-content {
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
    color: #ffffff !important;
    background: #2c3940 !important;
  }
  .modal-title {
    margin: auto;
    width: 100%;
  }
  .modal-header {
    padding: 0;
    border-bottom: none;
  }
  .modal-footer {
    display: block !important;
    padding: 0;
    border: none;
    text-align: center;
  }
  .modal-body {
    padding: 0;
  }
  .close {
    display: none;
  }
  .question-mark {
    color: black;
    background-color: white;
    padding: 0 5px;
    border-radius: 100%;
    font-weight: 700;
    margin: auto 10px;
    font-size: small;
  }
  .modal-title {
    line-height: 1.5;
  }
  .security_back_button {
    position: absolute;
    left: 14px;
    top: 26px;
    font-size: 20px;
    font-weight: 500;
    color: #ffcc10;
    cursor: pointer;
  }
}

.analytics-modal {
  min-width: 1000px;

  .nav-tabs {
    .nav-item {
      .nav-link {
        color: #525f7f !important;
      }
    }
  }

  .card-header {
    display: none;
  }

  .table-responsive {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  table {
    &.buy {
      th {
        color: #58c8a4;
      }
    }

    &.sell {
      th {
        color: #ff725c;
      }
    }

    span {
      &.buy {
        color: #58c8a4;
      }

      &.sell {
        color: #ff725c;
      }
    }
  }
}
